import Choices from 'choices.js';

function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
}

window.autosizeTextarea = autosizeTextarea;

autosizeTextarea();

/*const choices = document.querySelectorAll('.js-choice');

choices.forEach((choice) => {
  new Choices(choice, {
    searchEnabled: false,
    itemSelectText: '',
  });
});*/

let choicesAllArr = [];
const defaultSelectArr = document.querySelectorAll('.select');

function selectsLogic() {
  const choicesDOM = document.querySelector('.js-choice');
  if (choicesDOM) {
    const choicesArr = document.querySelectorAll('.js-choice');
    for (let i = 0; i < choicesArr.length; i++) {
      const parentContainer = choicesArr[i].parentNode;
      const originalElement =  parentContainer;
      const clonedElement = originalElement.cloneNode(true);

      /*console.log(parentContainer);*/

      /*const list = parentContainer.querySelector(".default-select__list");*/

      /*			console.log(choicesArr);*/

      if(!parentContainer.classList.contains('choices__inner')) {

        let allOptions = [];
        choicesArr[i].querySelectorAll('option').forEach(option => {
          allOptions.push(option);
        });

        let isOpen = false;

        let removeItemButton = false;

        if(choicesArr[i].parentElement.classList.contains('multiple-old-select')) {
          removeItemButton = true;
        }

        /*const elementToWrap = choicesArr[i];

        const wrapperDiv = document.createElement('div');

        wrapperDiv.classList.add('w-full', 'choices-container');

        elementToWrap.parentNode.insertBefore(wrapperDiv, elementToWrap);

        wrapperDiv.appendChild(elementToWrap);*/

        const choices = new Choices(choicesArr[i], {
          searchEnabled: false,
          itemSelectText: "",
          placeholder: true,
          shouldSort: false,
          removeItemButton: removeItemButton,
          /*classNames: {
            flippedState: '',

          },*/
        });

        const toggleSpan = document.createElement('span');
        toggleSpan.classList.add('choices-toggle');

        const firstChild = parentContainer.firstChild;

        parentContainer.insertBefore(toggleSpan, firstChild);

        /*console.log(choices);*/

        if(!parentContainer.classList.contains('sort-select')) {
          choicesAllArr.push(choices)
        }

        /*const choicesMultipleElement = parentContainer.querySelector(
            ".choices[data-type='select-multiple']"
        );

        if(!choicesMultipleElement) {
          toggleSpan.style.display = 'none';
        } else {
          /!*choices.containerOuter.element.addEventListener('showDropdown', function () {
            isOpen = true;
          });

          choices.containerOuter.element.addEventListener('hideDropdown', function () {
            isOpen = false;
          });*!/

          if (toggleSpan) {
            toggleSpan.addEventListener("click", () => {
              if (parentContainer.querySelector(".is-open")) {
                choices.hideDropdown();
              } else {
                choices.showDropdown();
              }
            });
          }
        }*/

        /*if (window.innerWidth >= 1025) {
          if (choicesMultipleElement) {
            choicesMultipleElement.addEventListener("click", () => {
              if (parentContainer.querySelector(".is-open")) {
                choices.hideDropdown();
              }
            });
          }
        } else {
          if (choicesMultipleElement) {
            choicesMultipleElement.addEventListener("click", (event) => {
              if (parentContainer.querySelector(".is-open")) {
                choices.hideDropdown();
                event.preventDefault();
              }
            });
          }
        }*/

        /*/!* New multiselect logic *!/
        if (
            choicesArr[i].multiple &&
            choicesArr[i].hasAttribute("data-multiple-list-logic")
        ) {
          let optionName = null;
          let optionValue = null;

          let selectAllFlag = false;

          const multiplePlaceholder = parentContainer.querySelector(
              ".choices__list--multiple"
          );

          const list = document.createElement("ul");
          list.className = "default-select__list";
          parentContainer.appendChild(list);

          function createListItem(optionName, optionValue) {
            if(!clonedElement.classList.contains('add-select-all')) {

              if(clonedElement.classList.contains('is-select-all')) {
                clonedElement.classList.add('add-select-all');
                const listItem = document.createElement("li");
                listItem.setAttribute("data-val", 'All');
                listItem.innerHTML = `Select All`;
                listItem.classList.add("default-select__list-item");
                list.appendChild(listItem);

                listItem.addEventListener("click", () => {
                  const listArr = parentContainer.querySelectorAll('.default-select__list');

                  if(listArr.length) {
                    for (let i = 0; i < listArr.length; i++) {
                      listArr[i].remove();
                    }
                    list.remove();
                  }
                  clonedElement.classList.remove('add-select-all');
                  clonedElement.classList.remove('is-select-all');

                  /!*choices.removeActiveItems();*!/
                  choices.destroy();
                  originalElement.parentNode.replaceChild(clonedElement, originalElement);
                  const options = clonedElement.querySelectorAll('option');
                  /!*console.log(options)*!/

                  for (let i = 0; i < options.length; i++) {
                    /!*if(options[i].value === 'All') {
                      options[i].remove();
                    }*!/
                    options[i].removeAttribute('selected');
                  }
                  updateLogic();
                });
              } else {
                const listItem = document.createElement("li");
                listItem.setAttribute("data-val", optionValue);
                listItem.innerHTML = `${optionName}`;
                listItem.classList.add("default-select__list-item");
                list.appendChild(listItem);

                listItem.addEventListener("click", () => {
                  handleListItemClick(listItem);
                });
              }
            }
          }

          function handleSelectedOptions() {
            list.innerHTML = "";

            const selectedOptions = Array.from(choicesArr[i].selectedOptions);

            if (selectedOptions.length >= 1) {
              list.classList.add("is-visible");
            } else {
              list.classList.remove("is-visible");
            }

            if(selectedOptions.length === 1) {
              for (let j = 0; j < selectedOptions.length; j++) {
                if (selectedOptions[j].textContent === 'Select') {
                  list.classList.remove("is-visible");
                }
              }
            }

            if(selectedOptions.length === 2) {
              for (let j = 0; j < selectedOptions.length; j++) {
                if (selectedOptions[j].textContent === 'Select All') {
                  list.classList.remove("is-visible");
                }
              }
            }

            if (selectedOptions.length === 0 && !choicesArr[i].multiple) {
              choices.setChoiceByValue("");
            }

            selectedOptions.forEach(function (option) {
              optionName = option.textContent;
              optionValue = option.value;
              if (optionName !== "Select" && optionName !== "Select All") {
                createListItem(optionName, optionValue);
              }

            });

            const listArr = list.querySelectorAll(".default-select__list-item");
            if (listArr.length === 1) {
              multiplePlaceholder.textContent = optionName.replace(/&amp;/g, "&");
            } else if (listArr.length >= 2) {
              multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
            } else {
              multiplePlaceholder.textContent = "Select";
            }

            if(clonedElement.classList.contains('add-select-all')) {
              multiplePlaceholder.textContent = `Selected All items`;
            }
          }

          function handleAllOptions() {
            if(!choicesArr[i].classList.contains('is-select-all')) {

              const selectedOptions = Array.from(choicesArr[i].selectedOptions);

              selectedOptions.forEach(function (option) {
                optionName = option.textContent;
                optionValue = option.value;

                if(optionName === "Select All") {
                  const listArr = parentContainer.querySelectorAll('.default-select__list');

                  if(listArr.length) {
                    for (let i = 0; i < listArr.length; i++) {
                      listArr[i].remove();
                    }
                    list.remove();
                  }
                  clonedElement.classList.add('is-select-all');

                  choices.destroy();
                  originalElement.parentNode.replaceChild(clonedElement, originalElement);
                  const options = clonedElement.querySelectorAll('option');
                  /!*console.log(options)*!/

                  for (let i = 0; i < options.length; i++) {
                    options[i].setAttribute('selected', 'selected');
                  }
                  choicesArr[i].classList.add('is-select-all');
                  updateLogic();
                }
              });
            }

            const listArr = list.querySelectorAll(".default-select__list-item");
            if (listArr.length === 1) {
              multiplePlaceholder.textContent = optionName.replace(/&amp;/g, "&");
            } else if (listArr.length >= 2) {
              multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
            } else {
              multiplePlaceholder.textContent = "Select";
            }
          }

          function handleListItemClick(listItem) {
            const optionValue = listItem.getAttribute("data-val");

            choices.removeActiveItemsByValue(optionValue);
            handleSelectedOptions();

            if(document.querySelector('.first-form-container')) {
              if (choicesArr[i].getAttribute("id") === "all-departments") {
                load('jobs/roles_process', 'sectors#all-departments');
              }

              if (choicesArr[i].getAttribute("id") === "state") {
                load('jobs/markets_process', 'states#state');
              }
            }

            if(document.querySelector('#alerts-update-loads')) {
              if (choicesArr[i].getAttribute("id") === "all-departments") {
                load('profile/roles_process', 'sectors#all-departments');
              }

              if (choicesArr[i].getAttribute("id") === "state") {
                load('profile/markets_process', 'states#state');
              }
            }

            if(document.querySelector('#alerts-update-loads-live')) {
              if (choicesArr[i].getAttribute("id") === "all-departments") {
                load('profile_live/roles_process', 'sectors#all-departments');
              }

              if (choicesArr[i].getAttribute("id") === "state") {
                load('profile_live/markets_process', 'states#state');
              }
            }
          }

          handleSelectedOptions();

          choicesArr[i].addEventListener("change", function () {
            handleSelectedOptions();
            handleAllOptions();
          });

          list.addEventListener("click", function (event) {
            const liElement = event.target.closest(".default-select__list-item");
            if (liElement) {
              handleListItemClick(liElement);
            }

            const selectedOptions = Array.from(choicesArr[i].selectedOptions);

            /!*console.log(selectedOptions)*!/

            /!*if (selectedOptions.length >= 1) {
              list.classList.add("is-visible");
            } else {
              list.classList.remove("is-visible");
            }
*!/
            if(selectedOptions.length === 1 || selectedOptions.length === 2) {
              /!*console.log(1234)*!/
              for (let j = 0; j < selectedOptions.length; j++) {
                if (selectedOptions[j].textContent === 'Select All') {
                  choices.destroy();
                  list.classList.remove("is-visible");
                  selectsLogic();
                }
              }
            }


          });
        }*/
      }

    }
  }
}
selectsLogic();

window.beefUpElements = []

/* ---- Filters ---- */
const initChekboxesChain = (parent, beefUp, bindAllCheckboxes = false, callback) => {
  const parentCheckbox = parent.querySelector("input[type='checkbox']");

  // Set attribute to current filters box
  if (beefUp) {
    beefUp[0].setAttribute("data-beefup", parentCheckbox.value)
  }

  const parentElement = $(parentCheckbox).closest(".filters-parent")[0];

  const currentChildren = Array.from(parentElement.nextElementSibling?.querySelectorAll(":scope > *") || []).map(item => item.querySelector("input[type='checkbox']"));

  const bindCheckboxes = (checkbox) => {
    const similarCheckboxes = Array.from(document.querySelectorAll(`input[value='${checkbox.value}']`)).filter(input => input.name !== "sector[]" && input.name !== "types[]");

    if (similarCheckboxes.length > 0) {
      similarCheckboxes.forEach(el => {
        if(checkbox.name !== "sector[]"&& checkbox.name !== "types[]") {
          el.checked = checkbox.checked;
        }

        // if (checkbox.checked) {
        // 	beefUp.open();
        // } else {
        // 	beefUp.close()
        // }
      })
    }
  }

  parentCheckbox.addEventListener("change", (e) => {
    if (bindAllCheckboxes) {
      bindCheckboxes(parentCheckbox);

      const similarBeefUpElements = window.beefUpElements.filter(beef => {
        return !beef.closest("#filter-sectors")[0] && !beef.closest("#filter-types")[0] && beef[0].dataset.beefup === e.target.value
      });

      console.log(similarBeefUpElements)

      if (similarBeefUpElements.length > 0) {
        similarBeefUpElements.forEach(similarBeefUp => {
          if (parentCheckbox.checked) {
            similarBeefUp.open();
          } else {
            similarBeefUp.close()
          }
        })
      }
    }

    if (currentChildren?.length > 0) {
      if (parentCheckbox.checked) {
        beefUp.open();
      } else {
        beefUp.close();

        currentChildren.forEach(checkbox => {
          checkbox.checked = false;
          bindCheckboxes(checkbox)
        });
      }
    }

    if (callback) callback()

    loadJobsFunc()
  });

  if (currentChildren?.length > 0) {
    currentChildren.forEach((checkbox, i, array) => {
      checkbox.addEventListener("change", (e) => {
        if (checkbox.checked) {
          parentCheckbox.checked = false;
          bindCheckboxes(parentCheckbox)
          /*console.log(1)*/
        } else {
          /*console.log(2)*/
        }

        if (bindAllCheckboxes) {
          bindCheckboxes(checkbox)
          /*console.log(3)*/
        }

        const isAllDeselected = array.every(item => !item.checked);

        if (!parentCheckbox.checked && isAllDeselected) {
          console.log(parentCheckbox)
          parentCheckbox.checked = true;
          const name = parentCheckbox.name;
          const value = parentCheckbox.value;

          const matchingCheckboxes = document.querySelectorAll(`input[type="checkbox"][name="${name}"][value="${value}"]`);

          matchingCheckboxes.forEach(checkbox => {
            checkbox.checked = true;
          });

          /*console.log(4)*/
        }

        if (callback) callback()
      })
    });
  }
}

const jobsFilters = document.querySelectorAll(".jobs-filters .filters-box");
if (jobsFilters.length) {
  jobsFilters.forEach(filterElement => {
    const parent = filterElement.querySelector(".filters-parent");

    if (parent.nextElementSibling?.classList.contains("filters-children")) {
      const checkboxBeefUp = $(filterElement).beefup({
        content: ".filters-children",
      });

      window.beefUpElements.push(checkboxBeefUp);

      initChekboxesChain(parent, checkboxBeefUp,  true, () => {
        loadJobsFunc()
      })
    } else {
      initChekboxesChain(parent, null,  false, () => {
        loadJobsFunc()
      })
    }
  })
}

/*  Multi Select */
function customMultipleSelect(element) {
  const mainInput = element.querySelector("input[readonly]");

  const parentBlocks = element.querySelectorAll(".filters-parent");

  const mainFiltersBoxes = element.querySelectorAll(".filters-box");

  mainFiltersBoxes.forEach((box, i) => {
    const checkboxBeefUp = $(box).beefup({
      content: ".filters-children",
    });


    window.beefUpElements.push(checkboxBeefUp);

    if (element.classList.contains("multiple-select--jobs")) {
      initChekboxesChain(parentBlocks[i], checkboxBeefUp, true)
    } else {
      initChekboxesChain(parentBlocks[i], checkboxBeefUp)
    }

  });

  // Dropdown visibility
  let closeFlag;

  element.addEventListener("click", () => element.classList.add("open"));

  mainInput.addEventListener("focus", () => element.classList.add("open"));

  mainInput.addEventListener("focusout", () => {
    if (closeFlag) element.classList.remove("open");
  });

  document.addEventListener("click", (event) => {
    if (!element.contains(event.target)) {
      element.classList.remove("open");
    }
  });

  element.addEventListener('click', () => {
    const layout = document.querySelector('.select-layout')
    layout.classList.add('active');
  })

  document.addEventListener('click', (e) => {
    const layout = document.querySelector('.select-layout')
    const mask = element.querySelector('.multiple-select__mask')
    if (e.target === layout || e.target === mask) {
      element.classList.remove('open');
      layout.classList.remove('active');
    }
  })

  element.addEventListener("mouseenter", () => closeFlag = false);

  element.addEventListener("mouseleave", () => closeFlag = true)
  // Checkboxes
  const checkboxes = element.querySelectorAll("input[type='checkbox']");

  checkboxes.forEach(checkbox => {
    checkbox.addEventListener("change", () => {
      const checkedValues = Array.from(checkboxes).filter(el => el.checked).map(el => el.value);

      const length = checkedValues.length;

      if (length) {
        mainInput.dataset.value = (length <= 3) ? checkedValues.join(", ") : `${length} selected`
      } else {
        mainInput.dataset.value = ""
      }
    })
  })
}

/*  Init Multi Select elements */
const multipleSelectItems = document.querySelectorAll(".multiple-select");
const layoutElem = document.querySelector('.select-layout')
if (multipleSelectItems.length) {
  multipleSelectItems.forEach(item => {
    customMultipleSelect(item)
  })
}

function customSelectsLogic() {
  const defaultCustomSelect = document.getElementsByClassName('default-custom-select')[0];
  if (defaultCustomSelect) {
    const defaultCustomSelectArr = document.querySelectorAll('.default-custom-select');

    defaultCustomSelectArr.forEach((customSelect, i) => {
      const zIndexValue = defaultCustomSelectArr.length - i;
      customSelect.style.zIndex = zIndexValue + 10;

      const customSelectPlaceholder = customSelect.querySelector('.default-custom-select__placeholder');
      const customSelectList = customSelect.querySelector('.default-custom-select__list');
      const customSelectPlaceholderTextContent = customSelect.querySelector('.default-custom-select__placeholder').textContent;

      customSelect.addEventListener('click', (e) => {
        if (!customSelect.classList.contains('active')) {
          customSelect.classList.add('active');
          console.log(customSelect);
        } else {
          if (!e.target.classList.contains('default-custom-select__list')) {
            customSelect.classList.remove('active');
          }
        }
      });

      document.addEventListener('click', (event) => {
        if (!customSelectList.contains(event.target) && !customSelect.contains(event.target)) {
          customSelect.classList.remove('active');
        }
      });

      const checkboxesSelect = customSelect.querySelectorAll('input[type="checkbox"]');

      function placeholderCheck() {
        const checkedCount = customSelect.querySelectorAll('input[type="checkbox"]:checked').length;
        console.log(customSelect.querySelectorAll('input[type="checkbox"]:checked'));

        if (checkedCount === 1) {
          const checkedItem = customSelect.querySelector('input[type="checkbox"]:checked');
          customSelectPlaceholder.textContent = checkedItem.nextElementSibling.nextElementSibling.textContent;
        } else {
          customSelectPlaceholder.textContent = checkedCount > 0 ? `${checkedCount} items selected` : customSelectPlaceholderTextContent;
        }
      }

      checkboxesSelect.forEach((checkbox) => {
        checkbox.addEventListener('change', () => {
          placeholderCheck();
        });
      });

      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      for (let j = 0; j < checkboxes.length; j++) {
        checkboxes[j].addEventListener('change', () => {
          placeholderCheck();
        });
      }

      placeholderCheck();
    });
  }
}
customSelectsLogic();

window.validateInputs = function validateInputs(jsonString) {
  const jsonData = JSON.parse(jsonString);

  const addErrorClass = (element, parentElement) => {
    parentElement.classList.add('error');
    setTimeout(() => {
      parentElement.style.transitionDelay = '0.8s';
    }, 300);

    parentElement.addEventListener('click', () => {
      parentElement.classList.remove('error');
      setTimeout(() => {
        parentElement.style.transitionDelay = '0.3s';
      }, 800);
    });
  };

  for (const formId in jsonData) {
    if (jsonData.hasOwnProperty(formId)) {
      const fieldNames = jsonData[formId];
      const form = document.getElementById(formId);

      if (form) {
        fieldNames.forEach(name => {
          const element = form.querySelector(`[name="${name}"], [name="${name}[]"]`);
          if (element) {
            let errorElement = element;

            if (element.classList.contains('choices__input')) {
              errorElement = element.parentElement;
            } else if (element.parentElement.classList.contains('jobs-list__checkbox')) {
              errorElement = element.parentElement.parentElement.parentElement;
            } else if (element.nextElementSibling && element.nextElementSibling.classList.contains('cke_1')) {
              errorElement = element.nextElementSibling;            }

            addErrorClass(element, errorElement);

          } else {
            console.warn(`Name field "${name}" not found in form with ID "${formId}".`);
          }
        });
      } else {
        console.warn(`Form with ID "${formId}" not found.`);
      }
    }
  }
};

window.updateLogic = function updateLogic() {
  selectsLogic();
}

window.counterInputsFunc = function counterInputsFunc() {
  const checkoutInputsArr = document.querySelectorAll('.checkout-input');

  checkoutInputsArr.forEach((input) => {
    input.addEventListener('input', function (event) {
      let value = this.value.trim();

      value = value.replace(/\D/g, '');

      if (parseInt(value) > 999) {
        value = '999';
      }

      this.value = value;
    });
  });
};
counterInputsFunc();

window.decreaseInputNumber = function decreaseInputNumber(inputId) {
  const input = document.getElementById(inputId);
  input.stepDown();

  const parentBlock = input.closest('.counter-parent-toggle');
  if ((Number(input.value) === 0 || !input.value) && (parentBlock && parentBlock.classList.contains('active'))) {
    parentBlock.classList.remove('active');
  }

  const event = new Event('change');
  input.dispatchEvent(event);
};

window.increaseInputNumber = function increaseInputNumber(inputId) {
  const input = document.getElementById(inputId);
  input.stepUp();

  const event = new Event('change');
  input.dispatchEvent(event);

  let value = input.value.trim();

  value = value.replace(/\D/g, '');

  if (parseInt(value) > 999) {
    value = '999';
  }

  input.value = value;
};