window.openCartFunc = function openCartFunc() {
  const cart = document.querySelector('.cart-popup');
  const cartBlock = document.querySelector('.cart-popup__block');
  const headerEl = document.querySelector('header');
  const topValue = window.getComputedStyle(headerEl).top;
  const headerHeight = headerEl.clientHeight;

  let cartTop = headerHeight;
  if (topValue.includes('px')) {
    const topNumber = parseInt(topValue, 10);
    if (topNumber < 0) {
      cartTop = Math.max(0, topNumber);
    }
  }

  cart.classList.add('active');
  cartBlock.style.top = `${cartTop}px`;

  const cartTopEl = cart.querySelector('.cart-popup__top');
  const cartBottomEl = cart.querySelector('.cart-popup__bottom');
  const cartContentEl = cart.querySelector('.cart-popup__content');
  const windowHeight = window.innerHeight;
  const paddingTop = parseInt(window.getComputedStyle(cartContentEl).paddingTop, 10);
  const paddingBottom = parseInt(window.getComputedStyle(cartContentEl).paddingBottom, 10);

  const cartScrollMaxHeight = windowHeight - paddingTop - paddingBottom - (cartTop + cartTopEl.clientHeight + cartBottomEl.clientHeight);

  console.log(cartScrollMaxHeight);

  scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
  scrollLock.addScrollableSelector('.simplebar-content-wrapper');

  const scrollContentElement = document.querySelector('.cart-popup__scroll');

  if (scrollContentElement) {
    scrollContentElement.style.maxHeight = `${cartScrollMaxHeight}px`;

    if (!cart.classList.contains('is-init-scroll')) {
      cart.classList.add('is-init-scroll');
      const simpleBarInstance = new SimpleBar(scrollContentElement);
      simpleBarInstance.recalculate();
    }
  }

  window.addEventListener('resize', () => {
    const newWindowHeight = window.innerHeight;
    const newCartScrollMaxHeight = newWindowHeight - paddingTop - paddingBottom - (cartTop + cartTopEl.clientHeight + cartBottomEl.clientHeight);
    scrollContentElement.style.maxHeight = `${newCartScrollMaxHeight}px`;
  });

  counterCartFunc();

};

window.closeCartFunc = function closeCartFunc() {
  const cart = document.querySelector('.cart-popup');
  cart.classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();

  if (action === 'cart') {
    location.reload();
  }
};

window.counterCartFunc = function counterCartFunc() {
  if (document.querySelector('.plan-list__item')) {
    const productCardsArr = document.querySelectorAll('.plan-list__item');

    productCardsArr.forEach((card) => {
      if (!card.classList.contains('is-init')) {
        card.classList.add('is-init');

        const counterBlock = card.querySelector('.plan-list__item-btn-counter');
        const btn = card.querySelectorAll('.btn')[card.querySelectorAll('.btn').length - 1];
        const input = card.querySelector('input');

        if(btn) {
          btn.addEventListener('click', (e) => {
            e.preventDefault();
            if (counterBlock) {
              counterBlock.classList.add('active');
              card.classList.add('is-open-counter');
            }

            if (input && input.value < 1) {
              input.stepUp();
            }
          });
        }
      }
    });
  }

  if (document.querySelector('.cart-list__item')) {
    const productCardsArr = document.querySelectorAll('.cart-list__item');

    productCardsArr.forEach((card) => {
      if (!card.classList.contains('is-init')) {
        card.classList.add('is-init');

        const counterBlock = card.querySelector('.cart-list__item-btn-counter');
        const btn = card.querySelectorAll('.btn')[card.querySelectorAll('.btn').length - 1];
        const input = card.querySelector('input');

        if(btn) {
          btn.addEventListener('click', (e) => {
            e.preventDefault();
            if (counterBlock) {
              counterBlock.classList.add('active');
              card.classList.add('is-open-counter');
            }

            if (input && input.value < 1) {
              input.stepUp();
            }
          });
        }
      }
    });
  }
}

counterCartFunc();
