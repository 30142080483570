import Swiper, { Navigation, Autoplay, Thumbs, EffectFade } from 'swiper';


const hiringSection = document.querySelector('.hiring-employers');

if (hiringSection) {
  const hiringSlider = document.querySelector('.hiring-employers-slider');
  let hiringSwiper = null;

  if (hiringSlider) {
    const slidesCount = hiringSlider.querySelectorAll('.swiper-slide').length;

    hiringSwiper = new Swiper(hiringSlider, {
      modules: [Navigation],
      slidesPerView: 1,
      spaceBetween: 16,
      centeredSlides: false,
      observer: true,
      observeParents: true,
      grid: {
        rows: 3,
        fill: 'column',
      },
      /*navigation: {
        prevEl: ".hiring-employers__slider-btn.prev",
        nextEl: ".hiring-employers__slider-btn.next",
      },*/
      allowTouchMove: false,
      breakpoints: {
        451: {
          slidesPerView: 1.5,
        },
        550: {
          slidesPerView: 2,
        },
        768: {
          centeredSlides: false,
          slidesPerView: 3,
        },
      },
    });
    if(slidesCount <= 6) {
      hiringSlider.querySelector('.swiper-wrapper').style.maxWidth = '500px';
    }
  }



  const companyCards = document.querySelectorAll(
    '.hiring-employers-slider__slide'
  );

  companyCards.forEach((card, index) => {
    const slideNumber = Math.floor(index / 3) + 1;
    card.setAttribute('data-slide', slideNumber);
  });
  const totalCards = companyCards.length;

  const lastSlideNumber = Math.ceil(totalCards / 3);

  const rotatedCompanyCards = [];

  const rows = Math.ceil(companyCards.length / 3);

  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < 3; x++) {
      rotatedCompanyCards[x * rows + y] = companyCards[y * 3 + x];
    }
  }

  const count = hiringSection.querySelector('.hiring-employers__count');
  const button = hiringSection.querySelector('.hiring-employers__btn');
  const prev = hiringSection.querySelector(
    '.hiring-employers__slider-btn.prev'
  );
  const next = hiringSection.querySelector(
    '.hiring-employers__slider-btn.next'
  );

  let activeIdx = 0;

  const setActiveCard = (cardRef, newIdx) => {
    next.classList.remove('opacity-50');
    prev.classList.remove('opacity-50');

    /*if (rotatedCompanyCards[activeIdx + 2] === undefined) {
      next.classList.add('opacity-50');
    }*/
    if (!cardRef) return;

    rotatedCompanyCards[activeIdx].classList.remove('active');
    activeIdx = newIdx;

    const { dataset } = cardRef;

    if (window.innerWidth < 951) {
      hiringSwiper.slideTo(dataset.slide - 1);
    } else {
      if (dataset.slide <= 2) {
        hiringSwiper.slideTo(0);
      } else {
        hiringSwiper.slideTo(dataset.slide - 2);
      }
    }
    cardRef.classList.add('active');

    count.textContent = dataset.count;
    button.textContent = `View All ${dataset.name} Jobs`;
    button.href = dataset.url;

    if(activeIdx === 0) {
      prev.classList.add('opacity-50');
    }
  };

  if (rotatedCompanyCards.length) {
    rotatedCompanyCards[0].classList.add('active');
    rotatedCompanyCards.forEach((item, idx) => {
      item?.addEventListener('click', () => {
        console.dir(item);

        rotatedCompanyCards[activeIdx].classList.remove('active');
        activeIdx = idx;

        setActiveCard(item, idx);
      });
    });

    prev.addEventListener('click', () => {
      if (activeIdx - 1 >= 0) {
        /*setActiveCard(rotatedCompanyCards[activeIdx - 1], activeIdx - 1);*/
        console.log(activeIdx - 1)
        if(rotatedCompanyCards[activeIdx - 1]) {
          setActiveCard(rotatedCompanyCards[activeIdx - 1], activeIdx - 1);
        } else {
          setActiveCard(rotatedCompanyCards[activeIdx - 2], activeIdx - 2);
        }
      }

    });
    next.addEventListener('click', () => {
      if (activeIdx + 1 <= rotatedCompanyCards.length - 1) {
        console.log(activeIdx + 1)
        if (rotatedCompanyCards[activeIdx + 1]) {
          setActiveCard(rotatedCompanyCards[activeIdx + 1], activeIdx + 1);
        } else {
          setActiveCard(rotatedCompanyCards[activeIdx + 2], activeIdx + 2);
        }
      }

      if (rotatedCompanyCards[activeIdx + 1] === undefined) {
        next.classList.add('opacity-50');
      }
    });
  }
}
