window.initChoices = function initChoices() {
    var textRemove = new Choices(
        document.getElementById('choices-text-remove-button'),
        {
          allowHTML: true,
          delimiter: ',',
          editItems: true,
          maxItemCount: 5,
          removeItemButton: true,
        }
      );
}
if (document.getElementById('choices-text-remove-button')) {
    initChoices();
}