import scrollLock from 'scroll-lock';

const burgerMenu = (headerSelector, burgerSelector,layout, mediaWidth, hideOnScroll=false) => {
  const menuElem = document.querySelector(headerSelector),
  layoutElem = document.querySelector(layout),
      burgerElem = document.querySelectorAll(burgerSelector);

  const removeClass = () => {
    layoutElem.classList.remove("active");
    menuElem.classList.remove("active");
    scrollLock.enablePageScroll();
  };
  removeClass();

  menuElem.querySelectorAll("a").forEach(link => {
    link.addEventListener("click", () => {
      menuElem.classList.contains("active") && removeClass();
    });
  });
  burgerElem.forEach(burgerBtn => {
    burgerBtn.addEventListener("click", () => {
    if (menuElem.classList.contains("active")) {
      removeClass();
    } else {
      layoutElem.classList.add("active");
      menuElem.classList.add("active");
      const method = 'margin';
      scrollLock.setFillGapMethod(method);
      scrollLock.disablePageScroll();

      if (document.querySelector(headerSelector) && innerWidth < 1025) {
        scrollLock.addScrollableSelector('.simplebar-content-wrapper');
      }

      scrollLock.addFillGapSelector(".employers-list__filter-box");
      scrollLock.addFillGapSelector(".jobs-list__filter-box");
    }
  });
})

  window.addEventListener("resize", () => {
    innerWidth > mediaWidth && removeClass()
  });

  layoutElem.addEventListener("click", () => {
      removeClass();
  });
	let scrollPoint;
  const stickyMenu = () => {
    window.scrollY >= 50 ? 
    menuElem.classList.add("sticky") :
    menuElem.classList.remove("sticky")

    if (  hideOnScroll) {
      if (window.scrollY > scrollPoint && innerWidth>1024) {
        menuElem.style.transform = "translateY(-100%)";
      } else {
        menuElem.style.transform = "unset";
      }
      scrollPoint = window.scrollY;

    }

  }
  
  window.addEventListener("scroll", stickyMenu);
  stickyMenu();
};

if (document.querySelector(".employers-list__filter-box")) {
  burgerMenu(".employers-list__filter-box", ".employers-list__burger-box", ".employers-list__layout", 1024);
}
if (document.querySelector(".jobs-list__filter-box")) {
  burgerMenu(".jobs-list__filter-box", ".jobs-list__burger-box", ".jobs-list__layout", 1024);
}