const viewJobsBySection = document.querySelector('.view-jobs-by');

if (viewJobsBySection) {
  const sectorsButon = document.querySelector('#sectors-btn');
  const locationButon = document.querySelector('#location-btn');

  const sectorSlider = viewJobsBySection.querySelector('#view-jobs-by-sector');
  const locationSlider = viewJobsBySection.querySelector(
    '#view-jobs-by-location'
  );

  sectorsButon.addEventListener('click', () => {
    sectorSlider.style.display = 'block';
    locationSlider.style.display = 'none';

    sectorsButon.classList.add('active');
    locationButon.classList.remove('active');
  });

  locationButon.addEventListener('click', () => {
    sectorSlider.style.display = 'none';
    locationSlider.style.display = 'block';

    locationButon.classList.add('active');
    sectorsButon.classList.remove('active');
  });
}
