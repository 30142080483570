const jobCardDescriptions = document.querySelectorAll('.job-card__description');

jobCardDescriptions.forEach((item) => {
  const { clientHeight, scrollHeight } = item;

  if (clientHeight < scrollHeight) {
    const { lineHeight } = getComputedStyle(item);
    const lines = Math.floor(clientHeight / parseFloat(lineHeight));
    console.log(lines);
    item.classList.add('trancate');
    item.style.setProperty('--lines', lines);
  }
});

if (document.getElementsByClassName('show-hide-content')[0]) {
  const btnShowHideArr = document.querySelectorAll('.btn-show-hide');
  const showHideContentArr = document.querySelectorAll('.show-hide-content');

  showHideContentArr.forEach((content, i) => {
    const items = content.querySelectorAll('label');

    const maxVisibleItems = content.dataset.visible ? parseInt(content.dataset.visible) : 7;

    items.forEach((item, index) => {
      if (index > maxVisibleItems - 1) {
        item.classList.add('hidden');
      }
    });


    if (items.length > maxVisibleItems) {
      const hiddenItems = content.querySelectorAll('label.hidden').length;

      const btnShowHide = btnShowHideArr[i];
      const btnCount = btnShowHide.querySelector('.btn-show-hide__count');
      btnShowHide.classList.add('is-visible');

      btnCount.textContent = `(${hiddenItems})`

      btnShowHide.addEventListener('click', () => {
        btnShowHide.classList.toggle('active');
        items.forEach((item, index) => {
          if (index > maxVisibleItems - 1) {
            item.classList.toggle('hidden');
          }
        });
      });
    }
  });
}

window.saveBtnInit = function saveBtnInit() {
  if(document.querySelector('.btn-save')) {
    const saveBtnArr = document.querySelectorAll('.btn-save');
    const site = document.querySelector('#site');

    saveBtnArr.forEach(btn => {
      const icon = btn.querySelector('.btn__icon');

      btn.addEventListener('click', () => {
        if(site.classList.contains('client-authorized') || site.classList.contains('candidate-authorized')) {

          if (icon.classList.contains('icon-star')) {
            icon.classList.remove('icon-star');
            icon.classList.add('icon-star-bg');
            /*icon.classList.add('!w-[19px]');*/
          } else {
            icon.classList.remove('icon-star-bg');
            /*icon.classList.remove('!w-[19px]');*/
            icon.classList.add('icon-star');
          }

        }
      })

    })
  }
}

saveBtnInit();