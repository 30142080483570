import Swiper, { Navigation, Autoplay, Thumbs, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils';

const logoSlider = document.querySelector('.logos-slider');

if (logoSlider) {

  const logosSlidesArr = logoSlider.querySelectorAll('.swiper-slide');
  let wrapper = logoSlider.querySelector('.swiper-wrapper');

  let loop = true;
  let loopAdditionalSlides = 5;

  if (logosSlidesArr.length <= 5) {
    loop = false;
    loopAdditionalSlides = false;
  }

  const logoSwiper = new Swiper(logoSlider, {
    slidesPerView: 1,
    spaceBetween: 8,
    loop: loop,
    loopAdditionalSlides: loopAdditionalSlides,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      550: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      },
    },
    modules: [Autoplay],
    on:{
      afterInit: function(){
        console.log(wrapper.scrollWidth, wrapper.clientWidth)
        wrapper.scrollWidth > wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
      },

      resize: function(){
        wrapper.scrollWidth > wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
      }
    }
  });

  swiperObserver(logoSwiper);
}

const latestVacanciesSlider = document.querySelector(
  '.latest-vacancies__slider'
);

if (latestVacanciesSlider) {
  const latestVacanciesSwiper = new Swiper(latestVacanciesSlider, {
    slidesPerView: 1,
    spaceBetween: 8,
    rewind: true,
    navigation: {
      prevEl: '.latest-vacancies__slider-btn.prev',
      nextEl: '.latest-vacancies__slider-btn.next',
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      901: {
        slidesPerView: 2,
        spaceBetween: 28,
      },
    },
    modules: [Navigation, Autoplay],
  });

  swiperObserver(latestVacanciesSwiper);
}

const viewJobsBySectorSlider = document.querySelector('#view-jobs-by-sector');

if (viewJobsBySectorSlider) {
  const viewJobsBySectorSlidesArr = viewJobsBySectorSlider.querySelectorAll('.swiper-slide');
  let wrapper = viewJobsBySectorSlider.querySelector('.swiper-wrapper');

  let loop = true;
  let loopAdditionalSlides = 2;
  let loopedSlides = false;

  if (viewJobsBySectorSlidesArr.length <= 3) {
    loop = false;
    loopAdditionalSlides = false;
  } else if (viewJobsBySectorSlidesArr.length <= 5 && window.innerWidth >= 1600) {
    loop = false;
    loopAdditionalSlides = false;
  } else {
    loopedSlides = viewJobsBySectorSlidesArr.length * 2;
  }

  const viewJobsBySectorSwiper = new Swiper(viewJobsBySectorSlider, {
    slidesPerView: 1,
    spaceBetween: 8,
    loop: loop,
    loopAdditionalSlides: loopAdditionalSlides,
    loopedSlides: loopedSlides,
    navigation: {
      prevEl: '#view-jobs-by-sector .view-jobs-by__slider-btn.prev',
      nextEl: '#view-jobs-by-sector .view-jobs-by__slider-btn.next',
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      901: {
        spaceBetween: 12,
        slidesPerView: 1,
      },
      1100: {
        spaceBetween: 12,
        slidesPerView: 3,
      },
      1600: {
        spaceBetween: 12,
        slidesPerView: 5,
      },
    },
    modules: [Navigation, Autoplay],
    on:{
      afterInit: function(){
        console.log(wrapper.scrollWidth, wrapper.clientWidth)
        wrapper.scrollWidth > wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
      },

      resize: function(){
        wrapper.scrollWidth > wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
      }
    }
  });

  swiperObserver(viewJobsBySectorSwiper);
}

const viewJobsByLocationSlider = document.querySelector(
  '#view-jobs-by-location'
);

if (viewJobsByLocationSlider) {
  const viewJobsBySectorSlidesArr = viewJobsByLocationSlider.querySelectorAll('.swiper-slide');
  let wrapper = viewJobsByLocationSlider.querySelector('.swiper-wrapper');

  let loop = true;
  let loopAdditionalSlides = 2;
  let loopedSlides = false;

  if (viewJobsBySectorSlidesArr.length <= 3) {
    loop = false;
    loopAdditionalSlides = false;
  } else if (viewJobsBySectorSlidesArr.length <= 5 && window.innerWidth >= 1600) {
    loop = false;
    loopAdditionalSlides = false;
  } else {
    loopedSlides = viewJobsBySectorSlidesArr.length * 2;
  }

  const viewJobsBySectorSwiper = new Swiper(viewJobsByLocationSlider, {
    slidesPerView: 1,
    spaceBetween: 8,
    loop: loop,
    loopAdditionalSlides: loopAdditionalSlides,
    loopedSlides: loopedSlides,
    navigation: {
      prevEl: '#view-jobs-by-location .view-jobs-by__slider-btn.prev',
      nextEl: '#view-jobs-by-location .view-jobs-by__slider-btn.next',
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      901: {
        spaceBetween: 12,
        slidesPerView: 1,
      },
      1100: {
        spaceBetween: 12,
        slidesPerView: 3,
      },
      1600: {
        spaceBetween: 12,
        slidesPerView: 5,
      },
    },
    modules: [Navigation, Autoplay],
    on:{
      afterInit: function(){
        console.log(wrapper.scrollWidth, wrapper.clientWidth)
        wrapper.scrollWidth > wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
      },

      resize: function(){
        wrapper.scrollWidth > wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
      }
    }
  });

  swiperObserver(viewJobsBySectorSwiper);
}

const insightsSlider = document.querySelector('.insights__slider');

if (insightsSlider) {
  const insightsSwiper = new Swiper(insightsSlider, {
    slidesPerView: 1,
    spaceBetween: 8,
    rewind: true,
    navigation: {
      prevEl: '.insights__slider-btn.prev',
      nextEl: '.insights__slider-btn.next',
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
      },
      901: {
        spaceBetween: 28,
      },
      1024: { spaceBetween: 28, slidesPerView: 3 },
    },
    modules: [Navigation, Autoplay],
  });

  swiperObserver(insightsSwiper);
}

const heroSlider = document.querySelector('.hero__slider');

if (heroSlider) {
  const insightsSwiper = new Swiper(heroSlider, {
    slidesPerView: 1,
    spaceBetween: 8,
    rewind: true,
    navigation: {
      prevEl: '.hero .prev',
      nextEl: '.hero .next',
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    modules: [Navigation, Autoplay],
  });

  swiperObserver(insightsSwiper);
}
const jobsSlider = document.querySelector('.jobs__slider');

if (jobsSlider) {
  const jobsSwiper = new Swiper(jobsSlider, {
    slidesPerView: 1,
    spaceBetween: 8,
    rewind: true,
    navigation: {
      prevEl: '.jobs .prev',
      nextEl: '.jobs .next',
    },
    breakpoints: {
      768: {
        spaceBetween: 28,
        slidesPerView: 2,
      },
    },
    // autoplay: {
    //   delay: 3500,
    //   disableOnInteraction: false,
    //   pauseOnMouseEnter: true,
    // },
    modules: [Navigation, Autoplay],
  });

  swiperObserver(jobsSwiper);
}

const testimonialsSlider = document.querySelector('.testimonials__slider');
const testimonialsLandSlider = document.querySelector('.testimonials--land');

if (testimonialsSlider) {
  const testimonialsSwiper = new Swiper(testimonialsSlider, {
    slidesPerView: 1,
    spaceBetween: 8,
    rewind: true,
    navigation: {
      prevEl: '.testimonials__nav .prev',
      nextEl: '.testimonials__nav .next',
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      654: {
        spaceBetween: testimonialsLandSlider ? 57 : 28,
        slidesPerView: 2,
      },
    },
    modules: [Navigation, Autoplay],
  });

  swiperObserver(testimonialsSwiper);
}


if (document.querySelector('.login__slider')) {
  const section = document.querySelector('.login');
  const title = document.querySelector('.login__title span');
  const slideTitles = document.querySelectorAll('.login__thumb .swiper-slide');

  const urlParams = new URLSearchParams(window.location.search);
  const hasCandidateParam = urlParams.has('t') && urlParams.get('t') === 'candidate';
  const hasEmployerParam = urlParams.has('t') && urlParams.get('t') === 'employer';

  let slidesPerView = 2;

  if(slideTitles.length === 1) {
    slidesPerView = 1;
    document.querySelector('.login__thumb-box').style.width = '188px';
  }

  const thumbSwiper = new Swiper('.login__thumb', {
    modules: [Navigation, Thumbs],
    slidesPerView: slidesPerView,
    watchSlidesProgress: true,
    spaceBetween: 5,
  });

  if(document.querySelector('.login__thumb').classList.contains('second-slide-active')) {
    thumbSwiper.slideTo(1);
  }

  if (hasCandidateParam) {
    thumbSwiper.slideTo(1);
  }

  if(hasEmployerParam) {
    thumbSwiper.slideTo(0);
  }

  const mainSlider = new Swiper('.login__slider', {
    modules: [Navigation, Thumbs, EffectFade],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    thumbs: {
      swiper: thumbSwiper,
    },
    allowTouchMove: false,
    noSwiping: true,
    autoHeight: true,

    on: {
      slideChange: ({ activeIndex }) => {
        title.textContent = slideTitles[activeIndex].textContent;
      }
    }
  });

  if(document.querySelector('.login__slider').classList.contains('second-slide-active')) {
    mainSlider.slideTo(1);
  }

  if (hasCandidateParam) {
    mainSlider.slideTo(1);
  }

  if(hasEmployerParam) {
    mainSlider.slideTo(0);
  }

  setTimeout(function () {
    section.style.opacity = 1;
  }, 50)
}

const valuesSlider = document.querySelector('.values__slider');

if (valuesSlider) {

  const valuesSlidesArr = valuesSlider.querySelectorAll('.swiper-slide');
  let wrapper = valuesSlider.querySelector('.swiper-wrapper');

  let loop = true;
  let loopAdditionalSlides = 5;

  if (valuesSlidesArr.length <= 5) {
    loop = false;
    loopAdditionalSlides = false;
  }

  const valueSlider = new Swiper(valuesSlider, {
    slidesPerView: 1,
    spaceBetween: 24,
    loop: loop,
    loopAdditionalSlides: loopAdditionalSlides,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.values__slider-nav .prev',
      nextEl: '.values__slider-nav .next',
    },
    breakpoints: {
      // 550: {
      //   slidesPerView: 1,
      //   spaceBetween: 10,
      // },
      620: {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      925: {
        slidesPerView: 3,
        spaceBetween: 18,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 18,
      },
      1400: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
    modules: [Autoplay, Navigation],
    on:{
      afterInit: function(){
        console.log(wrapper.scrollWidth, wrapper.clientWidth)
        wrapper.scrollWidth > wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
      },

      resize: function(){
        wrapper.scrollWidth > wrapper.clientWidth ? wrapper.classList.remove('justify-center') :wrapper.classList.add('justify-center')
      }
    }
  });

  swiperObserver(valueSlider);
}

const ourTimelinesSlider = document.querySelector('.our-timeline__slider');

if (ourTimelinesSlider) {
  const ourTimelineSliderContainer = document.querySelector('.our-timeline__slider-container');
  const ourTimelineSliderNav = document.querySelector('.our-timeline__slider-nav');
  let loop = true;
  let loopAdditionalSlides = 5;

  const ourTimelineSlidesArr = ourTimelinesSlider.querySelectorAll('.swiper-slide');
  if (ourTimelineSlidesArr.length <= 5) {
    loop = false;
    loopAdditionalSlides = false;
  }

  if (ourTimelineSlidesArr.length < 3) {
    ourTimelineSliderNav.classList.add('hidden');
  }

  function updateSliderContainerHeight(swiper) {
    const activeIndex = swiper.realIndex;
    let totalHeight = 0;

    for (let i = 0; i < 3; i++) {
      const slide = ourTimelineSlidesArr[activeIndex + i];
      if (slide) {
        totalHeight += slide.offsetHeight;
      }
    }

    if (totalHeight > 0) {
      ourTimelineSliderContainer.style.height = `${totalHeight}px`;
    } else {
      setTimeout(() => {
        let delayedTotalHeight = 0;
        for (let i = 0; i < 3; i++) {
          const slide = ourTimelineSlidesArr[activeIndex + i];
          if (slide) {
            delayedTotalHeight += slide.offsetHeight;
          }
        }
        ourTimelineSliderContainer.style.height = `${delayedTotalHeight}px`;
      }, 100);
    }
  }

  const ourTimelineSlider = new Swiper(ourTimelinesSlider, {
    direction: 'vertical',
    slidesPerView: 'auto',
    spaceBetween: 0,
    autoHeight: false,
    loop: loop,
    loopAdditionalSlides: loopAdditionalSlides,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: '.our-timeline__slider-nav .prev',
      nextEl: '.our-timeline__slider-nav .next',
    },
    modules: [Navigation, Autoplay],
    on: {
      init: function(swiper) {
        updateSliderContainerHeight(swiper);
      },
      slideChange: function(swiper) {
        updateSliderContainerHeight(swiper);
      },
    },
  });

  ourTimelineSlider.on('resize', function () {
    updateSliderContainerHeight(ourTimelineSlider);
  });

  swiperObserver(ourTimelineSlider);
}