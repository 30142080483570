function dashboardMenu() {
    const burgerDash = document.querySelector('.dashboard__burger');
    const menuDash = document.querySelector('.dashboard__navigation');
    burgerDash.addEventListener('click', () => {
        menuDash.classList.toggle('is-open');
        burgerDash.classList.toggle('is-open');
    })
}

if (document.querySelector('.dashboard')) {
    dashboardMenu();
}