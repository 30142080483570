import './utils/vh.utils.js';
import './utils/animate-on-view.utils';

import './libs/aos.lib.js';
import './libs/countUp.lib.js';
import './libs/choices.lib.js';


import './components/header.component.js';
import './components/dashboardMenu.component.js';
import './components/searchPanel.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/sliders.component.js';
import './components/job-cards.component.js';
import './components/company-cards.component.js';
import './components/view-jobs-by.component.js';

import './components/cart.component.js';
import scrollLock from 'scroll-lock';
/*import SimpleBar from 'simplebar';*/

/*var popupFD = document.querySelector('#popup');
if (popupFD) {
  var popupBlock = popupFD.querySelector('#popup-block');

  window.pushPopup = function pushPopup(parentBlock, classHelper = '') {
    if (classHelper) {
      const cls = classHelper.split(' ');
      popupBlock.classList.add(...cls);
    }

    popupBlock.innerHTML = '';
    popupBlock.innerHTML = document.querySelector(parentBlock).innerHTML;

    document.querySelector('body').classList.add('overflow-hidden');
    popupFD.classList.add('active');
    // scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    var scrollContentElement = document.querySelectorAll('.js-popup-scroll');
    if (scrollContentElement) {
        scrollContentElement.forEach(scrollWrap => {
            var simpleBarInstance = new SimpleBar(scrollWrap);
            simpleBarInstance.recalculate();
        })


        let container = document.querySelector('.popup__scroll');

        const scrollableContainer = new SimpleBar(container);
        const scrollBar = container.querySelector('.simplebar-vertical .simplebar-scrollbar');

        if (scrollBar.style.display === 'none') {
            container.classList.add('is-bottom');
        }

        scrollableContainer.getScrollElement().addEventListener('scroll', function () {
            let isAtBottom = (this.scrollTop + this.clientHeight + 10 >= this.scrollHeight);

            if (isAtBottom) {
                container.classList.add('is-bottom');
            } else {
                container.classList.remove('is-bottom');
            }
        });
    }
  };

  window.closePopup = function closePopup() {
    document.querySelector('#popup').classList.remove('active');
    popupBlock.className = 'popup__block';
    scrollLock.clearQueueScrollLocks();
    scrollLock.enablePageScroll();
    document.querySelector('body').classList.remove('overflow-hidden');
  }
}*/

if(document.querySelector('.jobs-accordion')) {
  const jobsAccordionsParentArr = document.querySelectorAll('.jobs-accordion-parent');

  jobsAccordionsParentArr.forEach((jobsAccordion, index) => {
    const parentIcon = jobsAccordion.querySelector('.jobs-accordion__icon');
    const childrenList = jobsAccordion.querySelector('.jobs-accordion__list');

    const parentCheckbox = jobsAccordion.querySelector('input');

    const jobsAccordionsChildrenArr = jobsAccordion.querySelectorAll('.jobs-accordion-children');

    let childrenListHeight = 0;

    if (jobsAccordionsChildrenArr.length) {
      jobsAccordionsChildrenArr.forEach((listHeight) => {
        childrenListHeight = childrenListHeight + listHeight.clientHeight;
      });
    }

    if(childrenList) {
      jobsAccordion.classList.add('is-children-elements');
    }

    parentIcon.addEventListener('click', () => {
      console.log(!childrenList.classList.contains('is-open'))
      if(!childrenList.classList.contains('is-open')) {
        openParentList();
      } else {
        closeParentList();
      }
    })

    if(parentCheckbox) {
      parentCheckbox.addEventListener('change', () => {
        if(parentCheckbox.checked) {
          openParentList();
        } else {
          closeParentList();
        }
      })

      if(parentCheckbox.checked) {
        openParentList();
      } else {
        closeParentList();
      }
    }

    function openParentList() {
      childrenList.classList.add('is-open');
      parentIcon.classList.add('is-open');
      childrenList.style.maxHeight = `${childrenListHeight}px`;
    }

    function closeParentList() {
      childrenList.classList.remove('is-open');
      parentIcon.classList.remove('is-open');
      childrenList.style.maxHeight = '0px';
    }

    if (jobsAccordionsChildrenArr.length) {
      jobsAccordionsChildrenArr.forEach(jobsAccordionChildren => {
        const childrenCheckbox = jobsAccordionChildren.querySelector('input');

        childrenCheckbox.addEventListener('change', () => {
          if(childrenCheckbox.checked) {
            parentCheckbox.checked = false;
          } else {
            const anyChildChecked = Array.from(jobsAccordionsChildrenArr).some(child => {
              return child.querySelector('input').checked;
            });

            if (!anyChildChecked) {
              parentCheckbox.checked = true;
            }
          }
        });
      });
    }
  })
}

